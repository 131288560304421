import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Header from '../../components/Header';
import BasicDetails from '../../components/Pages/Profile/BasicDetails';
import Footer from '../../components/Footer/HomePageFooter';
import CreateProductModal from '../../components/Modals/CreateProductModal';
import CreateEventModal from '../../components/Modals/CreateEventModal';
import '../../assets/css/profile.css';
import {
  getGraphData,
  getClubRegistrationDetails,
  validateClubRegistrationDetails,
  getPendingPayoutForClub,
  getClubPayoutHistory,
} from '../../actions/clubActions';
import {
  getClubTopProducts,
  createClubProduct,
  getAllClubProducts,
  getMembershipProducts,
  getClubTopSponsers,
  uploadProductAndEventImages,
  deactivateProduct,
  descriptionTranslate,
} from '../../actions/productActions';
import { getMembers } from '../../actions/membershipAction';
import { createClubEvents, getClubEvents } from '../../actions/eventActions';
import SuccessMessage from '../../components/Alerts/SuccessMessage';
import { targetLanguages, getTargetLanguagesAndDescriptions } from '../../utils/helper';
import { withTranslation } from 'react-i18next';

const format1 = 'YYYY-MM-DD HH:mm:ss';

const eventTiers = [
  { id: 1, name: 'Silver' },
  { id: 2, name: 'Gold' },
  { id: 3, name: 'Platinum' },
  { id: 4, name: 'Free' },
];

const productBasicDetails = {
  title: '',
  price: '',
  expiryDate: '2030-12-31',
  productImages: [],
  description: '',
  crowdFunding: false,
  isTranlationCompleted: false,
  recurringProduct: false,
  recurringPeriod: null,
  productType: 'fixed',
  startingDate:  null, 
  retryingInterval: null,
};

const eventBasicDetails = {
  title: '',
  description: '',
  fromDate: '',
  toDate: '',
  location: '',
  latitude: '',
  longitude: '',
  eventImages: [],
  isTranlationCompleted: false,
};

class ClubProfile extends Component {
  state = {
    tabId: 0,
    clubId: '',
    openProductModal: false,
    openEventModal: false,
    clubProduct: productBasicDetails,
    clubEvent: eventBasicDetails,
    error: {},
    eventErrors: {},
    tickets: [{ tier: 'Silver', amount: '200' }],
    periodFilter: 'year',
    isFreeSponsorModalOpen: false,
    isProductUploaded: false,
    isEventCreated: false,
    translatedDescription: {
      EN: '',
      DE: '',
      FR: '',
      IT: '',
    },
    payoutPageDetails: { pageId: 0, pageSize: 10 },
    isTranslating: false,
  };

  componentDidMount() {
    const {
      authUserData,
      getClubRegistrationDetails: fetchClubDetails,
      getClubTopProducts: fetchTopProducts,
      getGraphData: fetchGraphData,
      getAllClubProducts: fetchAllClubProducts,
      getMembershipProducts: fecthMembershiProducts,
      getMembers: fetchAllMembers,
      getClubEvents: fetchAllEvents,
      getClubTopSponsers: fetchAllTopSponsers,
      getPendingPayoutForClub: fetchClubPendingPayout,
      getClubPayoutHistory: fetchClubPayouts,
    } = this.props;
    const { periodFilter, payoutPageDetails } = this.state;
    if (authUserData && authUserData.clubId) {
      this.setState({ clubId: authUserData.clubId });
      fetchClubDetails({ id: authUserData.clubId });
      fetchTopProducts({ clubId: authUserData.clubId, pageId: 0, pageSize: 5 });
      fetchAllTopSponsers({ clubId: authUserData.clubId, pageId: 0, pageSize: 3 });
      fetchAllClubProducts({ clubId: authUserData.clubId });
      fetchGraphData({ clubId: authUserData.clubId, period: periodFilter });
      fetchAllEvents({ clubId: authUserData.clubId });
      fetchClubPendingPayout({ clubId: authUserData.clubId });
      fetchClubPayouts({
        clubId: authUserData.clubId,
        pageId: payoutPageDetails.pageId,
        pageSize: payoutPageDetails.pageSize
      })
      fecthMembershiProducts({clubId: authUserData.clubId})
      fetchAllMembers({clubId: authUserData.clubId, pageId: 0, pageSize: 5,})
    }
  }

  handleTabClick = (tabIndex) => {
    this.setState({ tabId: tabIndex });
  };

  handleCreateProductModal = () => {
    const { openProductModal } = this.state;
    this.resetTranslateState();
    this.resetClubProduct();
    this.setState({ openProductModal: !openProductModal, isFreeSponsorModalOpen: false });
  };

  handleFreeProductModal = () => {
    this.setState({
      openProductModal: true,
      isFreeSponsorModalOpen: true,
    });
  };

  handleCreateEventModal = () => {
    const { openEventModal } = this.state;
    this.resetTranslateState();
    this.resetClubEvent();
    this.setState({ openEventModal: !openEventModal });
  };

  handleProductModalTextChange = (e) => {
    const { name, value, checked } = e.target;
    const { clubProduct, error } = this.state;
    clubProduct[name] = value;
    error[name] = '';
    this.setState({ clubProduct, error });
      };

  handleProductType = (e)=>{
    const { name, value } = e.target;
    console.log("name", name, "value", value)
    const { clubProduct, error } = this.state;
    clubProduct['productType'] = value;
    if (value === 'crowd') {
      clubProduct['crowdFunding'] = "true";
      clubProduct['recurringProduct'] = false;
      clubProduct.productType = 'crowd'
    } else if (value === 'subscription' || value === 'membership') {
      clubProduct['recurringProduct'] = true;
      clubProduct['crowdFunding'] = "false";
    } else {
      clubProduct['crowdFunding'] = "false";
      clubProduct['recurringProduct'] = false;
    }
  }
  

  handlePayoutPageSizeChange = (e) => {
    this.setState({
      payoutPageDetails: {
        pageId: 0,
        pageSize: e.target.value
      }
    });
  };

  handlePayoutPageChange = (e, page) => {
    const { pageDetails } = this.state;
    e.preventDefault();
    this.setState({
      payoutPageDetails: {
        ...pageDetails,
        pageId: page
      }
    });
  }

  isValidateProductForm = () => {
    let isValid = true;
    const { clubProduct, error, isFreeSponsorModalOpen } = this.state;
    if (!clubProduct.title) {
      isValid = false;
      error.title = 'Bitte erfasse einen Titel.';
    }
    if (!clubProduct.description) {
      isValid = false;
      error.description = 'Bitte erfasse eine Beschreibung.';
    }
    if (!isFreeSponsorModalOpen && (!clubProduct.price || clubProduct.price < 5)) {
      isValid = false;
      error.price = 'Bitte gib einen gültigen Preis an.';
    }
    if (!clubProduct.expiryDate) {
      isValid = false;
      error.expiryDate = 'Bitte erfasse ein Ablaufdatum.';
    }
    if (clubProduct.productImages.length === 0) {
      isValid = false;
      error.productImage = 'Bitte lade ein Produktbild hoch.';
    }
    this.setState({ error });
    return isValid;
  };

  handleProductCreate = async () => {
    if (!this.isValidateProductForm()) return;

    const {
      createClubProduct: createProduct,
      getAllClubProducts: fetchAllClubProducts,
      uploadProductAndEventImages: uploadProductImage,
      t,
    } = this.props;

    this.setState({
      isProductUploaded: true,
      isTranslating: true
    });

    try {
      const { clubProduct, clubId } = this.state;
      await this.translateText(clubProduct.description);
      const { translatedDescription } = this.state;

      const productData = {
        clubId,
        title: clubProduct.title,
        price: clubProduct.price,
        expiryDate: clubProduct.expiryDate,
        crowdFunding: clubProduct.crowdFunding,
        description: translatedDescription.EN?.substring(0, 240) || '',
        descriptionDe: translatedDescription.DE?.substring(0, 240) || '',
        descriptionFr: translatedDescription.FR?.substring(0, 240) || '',
        descriptionIt: translatedDescription.IT?.substring(0, 240) || '',
        recurringProduct: clubProduct.recurringProduct,
        recurringPeriod: clubProduct.recurringProduct ? clubProduct.recurringPeriod : null,
        productType: clubProduct.productType,
        startingDate: clubProduct.startingDate || null,
        retryingInterval: clubProduct.retryingInterval || null,
      };

      createProduct(
        productData,
        (productId) => {
          const fileData = new FormData();
          fileData.append('file', clubProduct.productImages[0]);
          fileData.append('entity', 'product');
          fileData.append('column', 'image');
          fileData.append('id', productId);

          uploadProductImage(fileData, () => {
            this.resetTranslateState();
            this.resetClubProduct();
            this.setState({
              isProductUploaded: false,
              isTranslating: false,
              openProductModal: false
            });
            fetchAllClubProducts({ clubId });
          });
        },
        t('product_create_success'),
        t('product_create_error')
      );
    } catch (error) {
      this.setState({
        isProductUploaded: false,
        isTranslating: false,
        error: { ...this.state.error, description: t('description_too_long') }
      });
    }
  };

  handleTicketAddClick = () => {
    let { tickets } = this.state;
    tickets = [...tickets, { tier: '', amount: '' }];
    this.setState({ tickets });
  };

  handleTicketRemoveClick = (index) => {
    const { tickets } = this.state;
    tickets.splice(index, 1);
    this.setState({ tickets });
  };

  handleTicketsFieldChange = (e, i) => {
    const { tickets } = this.state;
    tickets[i][e.target.name] = e.target.value;
    this.setState({ tickets });
  };

  handleClubEventFieldChange = (e) => {
    const { clubEvent, eventErrors } = this.state;
    clubEvent[e.target.name] = e.target.value;
    eventErrors[e.target.name] = '';
    this.setState({ clubEvent, eventErrors });
  };

  isValidateEventForm = () => {
    let isValid = true;
    const { clubEvent, eventErrors } = this.state;
    if (!clubEvent.title) {
      isValid = false;
      eventErrors.title = 'Bitte erfasse einen Titel.';
    } else if (clubEvent.title.length< 5 || clubEvent.title.length> 20) {
      isValid = false;
      eventErrors.title = 'Titel min.5 max.20 Buchstaben';
    }
    if (!clubEvent.description) {
      isValid = false;
      eventErrors.description = 'Bitte erfasse eine Beschreibung.';
    }
    if (clubEvent.description.length < 5) {
      isValid = false;
      eventErrors.description = 'Beschreibung min.5 ';
    }
    if (!clubEvent.fromDate) {
      isValid = false;
      eventErrors.fromDate = 'Bitte setze ein Startdatum.';
    }
    if (!clubEvent.toDate) {
      isValid = false;
      eventErrors.toDate = 'Bitte setze ein Enddatum.';
    }
    if (!clubEvent.location) {
      isValid = false;
      eventErrors.location = 'Bitte erfasse einen Ort.';
    }
    if (clubEvent.eventImages.length === 0) {
      isValid = false;
      eventErrors.eventImage = 'Bitte lade ein Event-Bild hoch.';
    }
    this.setState({ eventErrors });
    return isValid;
  };

  handleEventCreate = () => {
    if (this.isValidateEventForm()) {
      const { clubEvent, clubId, tickets, translatedDescription } = this.state;
      const {
        createClubEvents: createEvent,
        getClubEvents: fetchAllEvents,
        uploadProductAndEventImages: uploadEventImage,
      } = this.props;

      const eventData = {
        clubId,
        tickets,
        title: clubEvent.title,
        fromDate: clubEvent.fromDate,
        toDate: clubEvent.toDate,
        location: clubEvent.location,
        latitude: clubEvent.latitude,
        longitude: clubEvent.longitude,
        description: translatedDescription.EN,
        descriptionDe: translatedDescription.DE,
        descriptionFr: translatedDescription.FR,
        descriptionIt: translatedDescription.IT,
      };
      eventData.fromDate = moment(eventData.fromDate).format(format1);
      eventData.toDate = moment(eventData.toDate).format(format1);
      this.setState({ isEventCreated: true });
      createEvent(eventData, (eventId) => {
        fetchAllEvents({ clubId });
        const fileData = new FormData();
        fileData.append('file', clubEvent.eventImages[0]);
        fileData.append('entity', 'event');
        fileData.append('column', 'image');
        fileData.append('id', eventId);
        uploadEventImage(fileData, () => {
          this.resetTranslateState();
          this.setState({
            isEventCreated: false,
            openEventModal: false,
            clubEvent: eventBasicDetails,
            tickets: [{ tier: '', amount: '' }],
          });
        });
      });
    }
  };

  setEventLocation = (placeData) => {
    const { clubEvent } = this.state;
    const formattedAddress = placeData.formatted_address ? placeData.formatted_address : null;
    const geometry = placeData.geometry ? placeData.geometry : null;
    if (formattedAddress) {
      clubEvent.location = formattedAddress;
    }
    if (geometry) {
      clubEvent.latitude = geometry.location ? geometry.location.lat() : '';
      clubEvent.longitude = geometry.location ? geometry.location.lng() : '';
    }
    this.setState({ clubEvent });
  };

  goToEditProfile = () => {
    const { history } = this.props;
    history.push('/edit/profile/club');
  };

  findFreeSponserProduct = (products) => {
    if (products && products.length > 0) {
      const freeProduct = products.filter((product) => product.price === null);
      return freeProduct.length > 0;
    }
    return false;
  };

  goToRegistrationStep = () => {
    const {
      validateClubRegistrationDetails: validateSteps,
      registrationDetails,
      history,
    } = this.props;
    validateSteps(registrationDetails);
    history.push('/register/club');
  };

  findRegistrationStatus = (clubRegistrationData) => {
    let isCompleted = true;
    if (clubRegistrationData) {
      const isFirstStepCompleted = clubRegistrationData.club && clubRegistrationData.club.id;
      const isSecondStepCompleted = clubRegistrationData.contact && clubRegistrationData.contact.id;
      const isThirdStepCompleted = clubRegistrationData.bank && clubRegistrationData.bank.id;
      isCompleted = isFirstStepCompleted && isSecondStepCompleted && isThirdStepCompleted;
    }
    return isCompleted;
  };

  setProductImage = (file) => {
    const { clubProduct, error } = this.state;
    clubProduct.productImages.push(file);
    error.productImage = '';
    this.setState({ clubProduct, error });
  };

  removeProductImage = () => {
    const { clubProduct } = this.state;
    clubProduct.productImages = [];
    this.setState({ clubProduct });
  };

  setEventImage = (file) => {
    const { clubEvent, eventErrors } = this.state;
    clubEvent.eventImages.push(file);
    eventErrors.eventImage = '';
    this.setState({ clubEvent, eventErrors });
  };

  removeEventImage = () => {
    const { clubEvent } = this.state;
    clubEvent.eventImages = [];
    this.setState({ clubEvent });
  };

  handleChangeTimeLine = (e) => {
    const { getGraphData: fetchGraphData, authUserData } = this.props;
    this.setState({ periodFilter: e.target.value });
    fetchGraphData({ clubId: authUserData.clubId, period: e.target.value });
  };

  handleDeactivateProduct = (e, productId) => {
    e.preventDefault();
    const { deactivateProduct: deactivateClubProduct } = this.props;
    deactivateClubProduct({ id: productId });
  };

  handleProductDescChange = async (e) => {
    const descriptionText = e.target.value;
    if (descriptionText.trim()) {
      this.setState({ isTranslating: true });
      try {
        await this.translateText(descriptionText);
        this.setState(prevState => ({
          clubProduct: {
            ...prevState.clubProduct,
            isTranlationCompleted: true
          }
        }));
      } finally {
        this.setState({ isTranslating: false });
      }
    }
  };

  handleEventDescChange = async (e) => {
    const descriptionText = e.target.value;
    const { clubEvent } = this.state;
    await this.translateText(descriptionText);
    clubEvent.isTranlationCompleted = true;
    this.setState({ clubEvent });
  };

  resetTranslateState = () => {
    const { translatedDescription } = this.state;
    translatedDescription.EN = '';
    translatedDescription.DE = '';
    translatedDescription.FR = '';
    translatedDescription.IT = '';
    this.setState({ translatedDescription });
  };

  resetClubProduct = () => {
    const { clubProduct } = this.state;
    const currentProduct = { ...clubProduct };
    currentProduct.description = '';
    currentProduct.price = '';
    currentProduct.productImages = [];
    currentProduct.expiryDate = '2025-12-31';
    currentProduct.title = '';
    currentProduct.isTranlationCompleted = false;
    currentProduct.crowdFunding = false;
    this.setState({ clubProduct: currentProduct });
  };

  resetClubEvent = () => {
    const { clubEvent } = this.state;
    const currentEvent = { ...clubEvent };
    currentEvent.description = '';
    currentEvent.eventImages = [];
    currentEvent.fromDate = '';
    currentEvent.toDate = '';
    currentEvent.title = '';
    currentEvent.location = '';
    currentEvent.latitude = '';
    currentEvent.longitude = '';
    currentEvent.isTranlationCompleted = false;
    this.setState({ clubEvent: currentEvent });
  };


  translateText = async (descriptionText) => {
    if (!descriptionText?.trim()) return;

    this.setState({ isTranslating: true });
    try {
      let { translatedDescription } = this.state;
      this.resetTranslateState();
      const selectedLanguage = localStorage.getItem('language') || 'du';
      const { targetLanguagesList, descriptionList } = getTargetLanguagesAndDescriptions(
        translatedDescription,
        descriptionText,
        selectedLanguage
      );
      translatedDescription = descriptionList;

      await Promise.all(targetLanguagesList.map(async (targetLang) => {
        const response = await descriptionTranslate(descriptionText, targetLang);
        if (response) {
          translatedDescription[targetLang] = response.substring(0, 240);
        }
      }));

      this.setState({ translatedDescription });
    } finally {
      this.setState({ isTranslating: false });
    }
  };

  setTranlatedText = (selectedLanguageValue) => {
    const { translatedDescription } = this.state;
    let translatedText = '';
    switch (selectedLanguageValue) {
      case targetLanguages.german:
        translatedText = translatedDescription[targetLanguages.german];
        break;
      case targetLanguages.french:
        translatedText = translatedDescription[targetLanguages.french];
        break;
      case targetLanguages.italian:
        translatedText = translatedDescription[targetLanguages.italian];
        break;
      default:
        translatedText = translatedDescription[targetLanguages.english];
        break;
    }
    return translatedText;
  };

  handleTranslateOptions = (e) => {
    const { clubProduct } = this.state;
    const selectedLanguageValue = e.target.value;
    clubProduct.description = this.setTranlatedText(selectedLanguageValue);
    this.setState({ clubProduct });
  };

  handleEventTranslateOptions = (e) => {
    const { clubEvent } = this.state;
    const selectedLanguageValue = e.target.value;
    clubEvent.description = this.setTranlatedText(selectedLanguageValue);
    this.setState({ clubEvent });
  };

  render() {
    const {
      tabId,
      openProductModal,
      openEventModal,
      clubProduct,
      clubEvent,
      error,
      tickets,
      eventErrors,
      periodFilter,
      isFreeSponsorModalOpen,
      isProductUploaded,
      isEventCreated,
      payoutPageDetails,
    } = this.state;
    const {
      clubDetails,
      events,
      products,
      topProducts,
      topSponsers,
      isMsgTriggered,
      msg,
      graphData,
      registrationDetails,
      pendingPayout,
      clubPayouts,
      authUserData,
      membershipProducts
    } = this.props;
    const isFreeProductCreated = this.findFreeSponserProduct(products);
    const isCompletedRegistration = this.findRegistrationStatus(registrationDetails);
    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <SuccessMessage isMsgTriggered={isMsgTriggered} msg={msg} />
        <BasicDetails
          profileType="private"
          clubDetails={clubDetails}
          tabId={tabId}
          handleTabClick={this.handleTabClick}
          handleCreateProductModal={this.handleCreateProductModal}
          handleFreeProductModal={this.handleFreeProductModal}
          handleCreateEventModal={this.handleCreateEventModal}
          events={events}
          products={products}
          topProducts={topProducts}
          topSponsers={topSponsers}
          membershipProducts ={membershipProducts}
          graphData={graphData}
          goToEditProfile={this.goToEditProfile}
          goToRegistrationStep={this.goToRegistrationStep}
          isFreeProductCreated={isFreeProductCreated}
          isCompletedRegistration={isCompletedRegistration}
          periodFilter={periodFilter}
          handleChangeTimeLine={this.handleChangeTimeLine}
          handleDeactivateProduct={this.handleDeactivateProduct}
          pendingPayout={pendingPayout}
          handlePayoutPageSizeChange={this.handlePayoutPageSizeChange}
          handlePayoutPageChange={this.handlePayoutPageChange}
          handleCreateMembershipModal={this.handleCreateMembershipModal}
          payoutPageDetails={payoutPageDetails}
          clubPayouts={clubPayouts}
          authUserData={authUserData}
        />
        <CreateProductModal
          isLoading={isProductUploaded}
          product={clubProduct}
          errors={error}
          open={openProductModal}
          isFreeSponsorModalOpen={isFreeSponsorModalOpen}
          handleOpen={this.handleCreateProductModal}
          handleChange={this.handleProductModalTextChange}
          handleProductType={this.handleProductType}
          handleProductCreate={this.handleProductCreate}
          setProductImage={this.setProductImage}
          removeProductImage={this.removeProductImage}
          handleProductDescChange={this.handleProductDescChange}
          handleTranslateOptions={this.handleTranslateOptions}
          isTranslating={this.state.isTranslating}
        />
        <CreateEventModal
          errors={eventErrors}
          isLoading={isEventCreated}
          eventTiers={eventTiers}
          tickets={tickets}
          open={openEventModal}
          event={clubEvent}
          handleOpen={this.handleCreateEventModal}
          handleTicketAddClick={this.handleTicketAddClick}
          handleTicketRemoveClick={this.handleTicketRemoveClick}
          handleTicketsFieldChange={this.handleTicketsFieldChange}
          handleClubEventFieldChange={this.handleClubEventFieldChange}
          handleEventCreate={this.handleEventCreate}
          setEventLocation={this.setEventLocation}
          setEventImage={this.setEventImage}
          removeEventImage={this.removeEventImage}
          handleEventDescChange={this.handleEventDescChange}
          handleTranslateOptions={this.handleEventTranslateOptions}
        />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clubDetails: state.club.clubRegistrationDetails.club,
  graphData: state.club.graphData,
  authUserData: state.user.authUserData,
  events: state.event.clubEvents,
  products: state.product.clubAllProducts,
  membershipProducts: state.product.clubMembershipProducts,
  topProducts: state.product.topProducts,
  topSponsers: state.product.topSponsers,
  isMsgTriggered: state.formResponse.isMsgTriggered,
  msg: state.formResponse.msg,
  registrationDetails: state.club.clubRegistrationDetails,
  pendingPayout: state.club.pendingPayout,
  clubPayouts: state.club.clubPayouts,
});

const mapDispatchToProps = {
  getClubRegistrationDetails,
  getClubTopProducts,
  createClubProduct,
  getGraphData,
  getAllClubProducts,
  getMembershipProducts,
  getMembers,
  createClubEvents,
  getClubEvents,
  getClubTopSponsers,
  validateClubRegistrationDetails,
  deactivateProduct,
  uploadProductAndEventImages,
  getPendingPayoutForClub,
  getClubPayoutHistory,
};

ClubProfile.propTypes = {
  getClubRegistrationDetails: PropTypes.func.isRequired,
  getClubTopProducts: PropTypes.func.isRequired,
  getGraphData: PropTypes.func.isRequired,
  getAllClubProducts: PropTypes.func.isRequired,
  getMembershipProducts: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  createClubProduct: PropTypes.func.isRequired,
  createClubEvents: PropTypes.func.isRequired,
  getClubEvents: PropTypes.func.isRequired,
  getClubTopSponsers: PropTypes.func.isRequired,
  getPendingPayoutForClub: PropTypes.func.isRequired,
  getClubPayoutHistory: PropTypes.func.isRequired,
  validateClubRegistrationDetails: PropTypes.func.isRequired,
  deactivateProduct: PropTypes.func.isRequired,
  authUserData: PropTypes.shape({
    clubId: PropTypes.string,
  }),
  clubDetails: PropTypes.shape({}),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  membershipProducts: PropTypes.arrayOf(PropTypes.shape({})),
  topProducts: PropTypes.arrayOf(PropTypes.shape({})),
  topSponsers: PropTypes.arrayOf(PropTypes.shape({})),
  isMsgTriggered: PropTypes.bool,
  msg: PropTypes.string,
  graphData: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  registrationDetails: PropTypes.shape({}),
  uploadProductAndEventImages: PropTypes.func.isRequired,
};

ClubProfile.defaultProps = {
  clubDetails: {},
  authUserData: {
    clubId: '',
  },
  products: [],
  events: [],
  topProducts: [],
  membershipProducts: [],
  topSponsers: [],
  isMsgTriggered: false,
  msg: null,
  graphData: {},
  history: {},
  registrationDetails: {},
  pendingPayout: {},
  clubPayouts: {}
};


export default connect(mapStateToProps,mapDispatchToProps) (withTranslation()(ClubProfile));