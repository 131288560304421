import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { getMemberPaymentHistory } from '../../../actions/membershipAction';
import { getImageData } from '../../../utils/helper';
import Results from './ClubMembershipList';
import ClubMemberDetails from '../../Modals/ClubMemberModal';
import ClubAddMemberModal from '../../Modals/ClubAddMemberModal';

const calculateTotalDays = (recurringPeriod) => {
  const periods = {
    month: 30,
    week: 7,
    threeMonths: 90,
    fourMonths: 120,
    sixMonths: 180,
    year: 365,
  };
  return periods[recurringPeriod] || 1;
};

const Members = ({ data }) => {
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const { members } = useSelector((state) => state.member);
  const { t, i18n } = useTranslation();

  const [product, setProduct] = useState(null);
  const [startingDate, setStartingDate] = useState('');
  const [initialAmount, setInitialAmount] = useState(0);
  const [, setMembersList] = useState([]);
  const [pageDetails, setPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const [popupOpen, setPopupOpen] = useState(false);
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const calculateStartingDate = useCallback((currentPeriod) => {
    return new Date(currentPeriod) > new Date() ? new Date(currentPeriod) : new Date();
  }, []);

  const calculateInitialAmount = useCallback(
    (startDate, currentPeriod, amount, recurringPeriod) => {
      const totalDays = calculateTotalDays(recurringPeriod);
      const daysDifference =
        (new Date(startDate) - new Date(currentPeriod)) / (1000 * 60 * 60 * 24);
      return new Date(startDate) > new Date(currentPeriod)
        ? (((totalDays - daysDifference) / totalDays) * amount).toFixed(2)
        : amount;
    },
    []
  );

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  useEffect(() => {
    if (product) {
      const startDate = moment(calculateStartingDate(product.currentPeriod)).format('YYYY-MM-DD');
      const amount = calculateInitialAmount(
        startDate,
        product.currentPeriod,
        product.price,
        product.recurringPeriod
      );
      setStartingDate(startDate);
      setInitialAmount(amount);
    }
  }, [product, calculateStartingDate, calculateInitialAmount]);

  useEffect(() => {
    const getNewMembersList = async () => {
      const newMembersList = await Promise.all(
        (members.content || []).map(async (item) => {
          if (item.image) {
            const imageData = await getImageData(authUserData, item.image);
            return { ...item, imageUrl: imageData || '' };
          }
          return item;
        })
      );
      setMembersList(newMembersList);
    };
    getNewMembersList();
  }, [members, authUserData]);

  const handleSelectionChange = (event) => {
    setProduct(event.target.value);
    setPageDetails({ ...pageDetails, pageId: 0 });
  };

  const handlePageChange = (e, page) => {
    e.preventDefault();
    setPageDetails({ ...pageDetails, pageId: page });
  };

  const handlePageSizeChange = (e) => {
    setPageDetails({ pageId: 0, pageSize: e.target.value });
  };

  const handleMoreClick = (userId, productId) => {
    dispatch(getMemberPaymentHistory({ userId, productId }));
    setPopupOpen(true);
  };

  const toggleAddMemberModal = () => setIsAddingMember(!isAddingMember);

  return (
    <div className="tab-pane product-tab" id="tab2">
      <div className="product-create-btn flex-row justify-content-between">
        <h4>{t('membership')}</h4>
        <Button onClick={toggleAddMemberModal} className="btn-global" variant="outlined">
          {t('add_member')}
        </Button>
      </div>

      <div>
        <form id="memberShipForm">
          <div className="product-create-btn">
            {t('mebership_type')}
            <br />
            <FormControl id="memberShipTypeDropdown" className="w-50">
              <Select value={product} onChange={handleSelectionChange}>
                {data.map((product) => (
                  <MenuItem key={product.id} value={product}>
                    {product.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <ClubMemberDetails
            open={popupOpen}
            closePopup={() => setPopupOpen(false)}
            member={selectedMember}
          />

          <ClubAddMemberModal
            open={isAddingMember}
            toggleAddMemberModal={toggleAddMemberModal}
            handleSelectionChange={handleSelectionChange}
            calculateStartingDate={calculateStartingDate}
            calculateInitialAmount={calculateInitialAmount}
            data={data}
            product={product}
            setProduct={setProduct}
            pageDetails={pageDetails}
            setPageDetails={setPageDetails}
            initialAmount={initialAmount}
            setInitialAmount={setInitialAmount}
            startingDate={startingDate}
            setStartingDate={setStartingDate}
            authUserData={authUserData}
          />
        </form>
      </div>

      <Results
        members={members.content || []}
        pageDetails={pageDetails}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        totalCount={members.totalElements}
        totalPages={members.totalPages}
        setPageDetails={setPageDetails}
        t={t}
        onClickMore={handleMoreClick}
        setemember={setSelectedMember}
      />
    </div>
  );
};

Members.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
      userImage: PropTypes.string,
    })
  ),
};

Members.defaultProps = {
  data: [],
};

export default Members;
