import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { countries } from '../../utils/countryList';
import { findDescriptionField, clubRoles, getClubRole } from '../../utils/helper';
import PlaceSearch from '../PlaceSearch';
import LogoUpload from '../Upload/LogoUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const getStyles = (type, sportType, theme) => ({
  fontWeight:
    sportType.indexOf(type) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const countryDropDown = () => (
  countries.map((country) => (
    <MenuItem key={country.code} value={country.code}>
      {country.name}
    </MenuItem>
  ))
);

const namePlaceholder = (value, t) => {
  const placeholders = {
    CLUB: t('club_name'),
    ATHLETE: t('athlete_name'),
    ACADEMY: t('academy_name'),
    FOUNDATION: t('foundation_name'),
  };
  return placeholders[value] || t('club_name');
};

const emailAddressPlaceholder = (value, t) => {
  const placeholders = {
    CLUB: t('club_email'),
    ATHLETE: t('athlete_email'),
    ACADEMY: t('academy_email'),
    FOUNDATION: t('foundation_email'),
  };
  return placeholders[value] || t('club_email');
};

const descriptionPlaceholder = (value, t) => {
  const placeholders = {
    CLUB: t('club_description'),
    ATHLETE: t('athlete_description'),
    ACADEMY: t('academy_description'),
    FOUNDATION: t('foundation_description'),
  };
  return placeholders[value] || t('club_description');
};

const webSitePlaceholder = (value, t) => {
  const placeholders = {
    CLUB: t('club_website'),
    ATHLETE: t('athlete_website'),
    ACADEMY: t('academy_website'),
    FOUNDATION: t('foundation_website'),
  };
  return placeholders[value] || t('club_website');
};

const useStyles = makeStyles((theme) => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      backgroundColor: '#f50',
    },
  },
  adminEmailTextBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  deleteIcon: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  addAdminText: {
    marginTop: theme.spacing(1),
    display: 'block',
    color: theme.palette.text.secondary,
  },
}));

const FirstStep = ({
  handleSave,
  handleChange,
  handleAddressChange,
  handleAdminEmail,
  handleEmailChange,
  handleRoleChange,
  handleEmailCheck,
  handleTypeChange,
  handleNatureChange,
  setAddressDetails,
  setClubLogo,
  removeClubLogo,
  removeAdminEmailTextField,
  savedLogo,
  types,
  values: {
    clubName,
    address: { address1, address2, postalCode, city, country },
    tags,
    email,
    website,
    contactNumber,
    nature,
    hideAddress,
  },
  adminEmailsInput,
  errors,
  handleDescriptionText,
  descriptionText,
  authUserData
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState(namePlaceholder(nature, t));
  const [emailAddress, setEmail] = useState(emailAddressPlaceholder(nature, t));
  const [description, setDescription] = useState(descriptionPlaceholder(nature, t));
  const [webSite, setWebSite] = useState(webSitePlaceholder(nature, t));
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const descriptionFieldName = findDescriptionField(selectedLanguage);
  const theme = useTheme();
  const natures = ['CLUB', 'ATHLETE', 'ACADEMY', 'FOUNDATION'];
  const clubRole = getClubRole(authUserData);
  const emailEditable = clubRole === 'ROLE_CLUB';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleClubNatureChange = (event) => {
    handleNatureChange(event);
    setPlaceHolder(event.target.value);
  };

  const setPlaceHolder = (value) => {
    setName(namePlaceholder(value, t));
    setEmail(emailAddressPlaceholder(value, t));
    setDescription(descriptionPlaceholder(value, t));
    setWebSite(webSitePlaceholder(value, t));
  };

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12}>
          <FormHelperText error>{errors.clubLogoFiles}</FormHelperText>
          <LogoUpload savedImages={savedLogo} setImage={setClubLogo} removeImage={removeClubLogo} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required className={classes.formControl}>
            <InputLabel>{t('type')}</InputLabel>
            <Select
              labelId="label_nature"
              id="nature"
              value={nature}
              onChange={handleClubNatureChange}
              MenuProps={MenuProps}
            >
              {natures.map((nature, index) => (
                <MenuItem key={index} value={nature} style={getStyles(nature, natures, theme)}>
                  {t(nature)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{errors.nature}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={name}
            name="clubName"
            placeholder={name}
            value={clubName || ''}
            onChange={handleChange}
            required
            error={!!errors.clubName}
            helperText={errors.clubName}
            inputProps={{ maxLength: 20 }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={emailAddress}
            name="email"
            placeholder={emailAddress}
            value={email || ''}
            onChange={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={webSite}
            name="website"
            placeholder={webSite}
            value={website || ''}
            onChange={handleChange}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12}>
          <PlaceSearch setAddressDetails={setAddressDetails} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line1')}
            name="address1"
            placeholder={t('address_line1')}
            value={address1 || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            required
            error={!!errors.address?.address1}
            helperText={errors.address?.address1}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line2')}
            name="address2"
            placeholder={t('address_line2')}
            value={address2 || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('post_code')}
            name="postalCode"
            placeholder={t('post_code')}
            value={postalCode || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            required
            error={!!errors.address?.postalCode}
            helperText={errors.address?.postalCode}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required className={classes.formControl}>
            <InputLabel>{t('country')}</InputLabel>
            <Select
              value={country}
              onChange={(e) => handleAddressChange(e, 'club')}
              name="country"
              error={!!errors.address?.country}
            >
              {countryDropDown()}
            </Select>
            <FormHelperText error>{errors.address?.country}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('city')}
            name="city"
            placeholder={t('city')}
            value={city || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            required
            error={!!errors.address?.city}
            helperText={errors.address?.city}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideAddress}
                onChange={handleChange}
                name="hideAddress"
                color="primary"
              />
            }
            label={t('hideAddress')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('phone')}
            name="contactNumber"
            placeholder={t('phone')}
            value={contactNumber || ''}
            onChange={handleChange}
            error={!!errors.contactNumber}
            helperText={errors.contactNumber}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required className={classes.formControl}>
            <InputLabel>{t('sport_kind')}</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              value={tags && tags.length ? tags[0].id : ''}
              onChange={handleTypeChange}
              MenuProps={MenuProps}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id} style={getStyles(type, types, theme)}>
                  {type.tag}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{errors.tags}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={description}
            name={descriptionFieldName}
            placeholder={description}
            value={descriptionText[descriptionFieldName]}
            onChange={handleDescriptionText}
            required
            error={!!errors[descriptionFieldName]}
            helperText={errors[descriptionFieldName]}
            inputProps={{ maxLength: 240 }}
            multiline
            rows={4}
          />
        </Grid>

        {emailEditable && (
          <Grid item xs={12}>
            {adminEmailsInput.map((input, index) => (
              <div key={`admin-email-${index}`} className={classes.adminEmailTextBox}>
                <TextField
                  onChange={(e) => handleEmailChange(e, index, input.role)}
                  onBlur={handleEmailCheck}
                  fullWidth
                  label={t('email_address')}
                  name="adminEmail"
                  value={input.email}
                  placeholder={t('email_address')}
                />
                <FormControl fullWidth required style={{ width: "70%", marginLeft: theme.spacing(2) }}>
                  <InputLabel>{t('role')}</InputLabel>
                  <Select
                    labelId="label_role"
                    id="role"
                    value={input.role}
                    onChange={(e) => handleRoleChange(e, index, input.email)}
                    MenuProps={MenuProps}
                  >
                    {clubRoles.map((role, roleIndex) => (
                      <MenuItem key={roleIndex} value={role} style={getStyles(role, clubRoles, theme)}>
                        {t(role)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DeleteForeverIcon
                  onClick={() => removeAdminEmailTextField(index)}
                  className={classes.deleteIcon}
                />
              </div>
            ))}
            <FormHelperText error>{errors.adminEmail}</FormHelperText>
            <Button
              variant="contained"
              onClick={handleAdminEmail}
              className={classes.btnColor}
              startIcon={<AddIcon />}
            >
              {t('add_admins')}
            </Button>
            <span className={classes.addAdminText}>{t('add_admins_text')}</span>
          </Grid>
        )}
      </Grid>

      {clubRole !== 'ROLE_CLUB_FINANCE' && (
        <div style={{ display: 'flex', marginTop: theme.spacing(5), justifyContent: 'flex-end' }}>
          <Button variant="contained" className={classes.btnColor} onClick={handleSave}>
            {t('save')}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

FirstStep.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAdminEmail: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  handleEmailCheck: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  handleNatureChange: PropTypes.func.isRequired,
  setAddressDetails: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  removeClubLogo: PropTypes.func.isRequired,
  handleDescriptionText: PropTypes.func.isRequired,
  setClubLogo: PropTypes.func.isRequired,
  removeAdminEmailTextField: PropTypes.func.isRequired,
  savedLogo: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({
    clubName: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    contactNumber: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    nature: PropTypes.string,
    hideAddress: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    clubName: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
    tags: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    nature: PropTypes.string,
    adminEmail: PropTypes.string,
    clubLogoFiles: PropTypes.string,
  }),
  adminEmailsInput: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string,
  })).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    tag: PropTypes.string,
  })),
  descriptionText: PropTypes.shape({}),
  authUserData: PropTypes.shape({}).isRequired,
};

FirstStep.defaultProps = {
  errors: {},
  types: [],
  savedLogo: [],
  descriptionText: {},
};

export default FirstStep;