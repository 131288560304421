import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Divider from '@material-ui/core/Divider';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import FinaceOverview from './FinaceOverview';
import Products from './ClubProducts';
import Events from './Events';
import TopProducts from './TopProducts';
import TopSponsers from './TopSponsers';
import Members from './ClubMembers';
import DonationOverview from './DonationOverview';
import Icon from '../../../assets/img/daily-income.svg';
import defaultImage from '../../../assets/img/product-holder.png';
import { imageBaseUrl, displayDescription, clubRoles, getClubRole } from '../../../utils/helper';
import CoverImageUpload from '../../Upload/CoverImageUpload';
import ClubLogoUpload from '../../Upload/ClubLogoUpload';
import QRCodePdf from '../../QRCode';
import PriceSlider from '../../PriceSlider';
import coverImage from '../../../assets/img/cover-user.jpg';
import ConfirmDialog from '../../Alerts/ConfirmDialog';

import { Helmet } from 'react-helmet';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
};

const reset = (id) => {
  if (document.getElementById('active')) {
    document.getElementById("active").removeAttribute('id');

    document.getElementById(`tab${id}`).children[0].setAttribute('id', 'active');
  }
};

const useStyles = makeStyles({
  eventLogo: {
    width: '100%',
    height: 160,
    objectFit: 'cover',
  },
  productImage: {
    width: '100%',
    maxWidth: 200,
    height: 110,
    objectFit: 'cover',
  },
  emptyPrice: {
    paddingBottom: 5,
  },
});

const BasicDetails = (props) => {
  const {
    tabId,
    handleTabClick,
    handleCreateProductModal,
    handleCreateEventModal,
    handleFreeProductModal,
    profileType,
    clubList,
    clubDetails,
    handleFavoriteClick,
    handleUnFavoriteClick,
    handleDonationClick,
    isFavorited,
    events,
    products,
    subscriptions,
    topProducts,
    membershipProducts,
    topSponsers,
    graphData,
    goToEditProfile,
    isFreeProductCreated,
    isCompletedRegistration,
    goToRegistrationStep,
    handleChangeTimeLine,
    periodFilter,
    handleDeactivateProduct,
    pendingPayout,
    clubPayouts,
    handlePayoutPageSizeChange,
    handlePayoutPageChange,
    payoutPageDetails,
    authUserData,
    openEmailVerification,
    setOpenEmailVerification,
    verificationEmailRequest
  } = props;
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const classes = useStyles();
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const viewEmailVerificationConfirm = () => {
    return (
      <ConfirmDialog
        title={t('verify_account')}
        open={openEmailVerification}
        setOpen={() => setOpenEmailVerification(false)}
        onConfirm={verificationEmailRequest}
        className={"btn-global delete-account-btn"}
        positiveRequest={true}
      >
        {t('send_verification')}
      </ConfirmDialog>);
  }

  const displayTabContent = (id) => {
    reset(id);
    switch (id) {
      case 0:
        return <DonationOverview graphData={graphData} sponserData={topSponsers} pendingPayout={pendingPayout} />;
      case 1:
        return (
          <FinaceOverview
            data={graphData}
            handleChangeTimeLine={handleChangeTimeLine}
            periodFilter={periodFilter}
            payouts={clubPayouts.content}
            pageDetails={payoutPageDetails}
            handlePageChange={handlePayoutPageChange}
            handlePageSizeChange={handlePayoutPageSizeChange}
            totalCount={clubPayouts.totalElements}
            totalPages={clubPayouts.totalPages}
            authUserData={authUserData}
          />
        );
      case 2:
        return <Events handleOpen={handleCreateEventModal} data={events} />;
      case 3:
        return (
          <Products
            handleOpen={handleCreateProductModal}
            data={products}
            isFreeProductCreated={isFreeProductCreated}
            handleDeactivateProduct={handleDeactivateProduct}
            handleFreeProductModal={handleFreeProductModal}
          />
        );
      case 4:
        return <TopProducts data={topProducts} />;
      case 5:
        return <TopSponsers data={topSponsers} />;
      case 6:
        return <Members data={membershipProducts} />;
      default:
        return null;
    }
  };

  const displayAddress = (data) => {
    if (data && data.address) {
      const addressLine1 = data.address.address1 ? data.address.address1 : '';
      const addressLine2 = data.address.address2 ? data.address.address2 : '';
      const city = data.address.city ? data.address.city : '';
      const state = data.address.country ? data.address.country : '';
      const postalCode = data.address.postalCode ? data.address.postalCode : '';
      return (
        <span>
          <p>
            {addressLine1}
          </p>
          <p>
            {addressLine2}
          </p>
          <p>
            {state}-{postalCode} {city}
          </p>
        </span>
      );
    }
    return null;
  };

  const displayTabs = (type) => {
    let content = null;
    let clubRole = authUserData && authUserData.roles ? getClubRole(authUserData) : ''
    if (clubRole === 'ROLE_CLUB') return displayTabsAdmin(type)
    if (clubRole === 'ROLE_CLUB_FINANCE') return displayTabsFinance(type)
    if (clubRole === 'ROLE_CLUB_OPERATOR') return displayTabsOperator(type)
    return content;
  };

  const displayTabsOperator = (type) => {
    let content = null;
    if (type === 'private') {
      content = (
        <div className="clubTabPanel club-profile-private">
          {/* <!-- Nav tabs --> */}
          <ul id="tabList" className="nav nav-tabs modern-tabs" role="tablist">
            <li id="tab0">
              <a id="active" className="pp-tab-link overview-tab" href="#/" onClick={() => handleTabClick(0)}>
                {t('overview')}
              </a>
            </li>
            {/* <li id="tab1">
              <a className="pp-tab-link" href="#/" onClick={() => handleTabClick(1)}>
                {t('financials')}
              </a>
            </li> */}
            <li id="tab1">
              <a className="pp-tab-link event-tab" href="#/" onClick={() => handleTabClick(2)}>
                {t('events')}
              </a>
            </li>
            <li id="tab3">
              <a className="pp-tab-link produkt-tab" href="#/" onClick={() => handleTabClick(3)}>
                {t('products')}
              </a>
            </li>
            <li id="tab6">
              <a className="pp-tab-link top-sponsor-tab" href="#/" onClick={() => handleTabClick(6)}>
                {t('membership')}
              </a>
            </li>
          </ul>
          {/* <!-- Tab panes --> */}
          <div className="tab-content big-padding top-color-border bottom-margin-40 tab-th modern-tabs-content">
            {displayTabContent(tabId)}
          </div>
          {/* <!-- eof .tab-content --> */}
        </div>
      );
    }
    return content;
  };

  const displayTabsFinance = (type) => {
    let content = null;
    if (type === 'private') {
      content = (
        <div className="clubTabPanel club-profile-private">
          {/* <!-- Nav tabs --> */}
          <ul id="tabList" className="nav nav-tabs modern-tabs" role="tablist">
            <li id="tab0">
              <a id="active" className="pp-tab-link overview-tab" href="#/" onClick={() => handleTabClick(0)}>
                {t('overview')}
              </a>
            </li>
            <li id="tab1">
              <a className="pp-tab-link fnancial-tab" href="#/" onClick={() => handleTabClick(1)}>
                {t('financials')}
              </a>
            </li>
            <li id="tab4">
              <a className="pp-tab-link top-product-tab" href="#/" onClick={() => handleTabClick(4)}>
                {t('top_products')}
              </a>
            </li>
            <li id="tab5">
              <a className="pp-tab-link top-sponsor-tab" href="#/" onClick={() => handleTabClick(5)}>
                {t('top_sponsers')}
              </a>
            </li>
          </ul>
          {/* <!-- Tab panes --> */}
          <div className="tab-content big-padding top-color-border bottom-margin-40 tab-th modern-tabs-content">
            {displayTabContent(tabId)}
          </div>
          {/* <!-- eof .tab-content --> */}
        </div>
      );
    }
    return content;
  };

  const displayTabsAdmin = (type) => {
    let content = null;
    if (type === 'private') {
      content = (
        <div className="clubTabPanel club-profile-private">
          {/* <!-- Nav tabs --> */}
          <ul id="tabList" className="nav nav-tabs modern-tabs" role="tablist">
            <li id="tab0">
              <a id="active" className="pp-tab-link overview-tab" href="#/" onClick={() => handleTabClick(0)}>
                {t('overview')}
              </a>
            </li>
            <li id="tab1">
              <a className="pp-tab-link fnancial-tab" href="#/" onClick={() => handleTabClick(1)}>
                {t('financials')}
              </a>
            </li>
            <li id="tab2">
              <a className="pp-tab-link event-tab" href="#/" onClick={() => handleTabClick(2)}>
                {t('events')}
              </a>
            </li>
            <li id="tab3">
              <a className="pp-tab-link produkt-tab" href="#/" onClick={() => handleTabClick(3)}>
                {t('products')}
              </a>
            </li>
            <li id="tab4">
              <a className="pp-tab-link top-product-tab" href="#/" onClick={() => handleTabClick(4)}>
                {t('top_products')}
              </a>
            </li>
            <li id="tab5">
              <a className="pp-tab-link top-sponsor-tab" href="#/" onClick={() => handleTabClick(5)}>
                {t('top_sponsers')}
              </a>
            </li>
            <li id="tab6">
              <a className="pp-tab-link top-sponsor-tab" href="#/" onClick={() => handleTabClick(6)}>
                {t('membership')}
              </a>
            </li>
          </ul>
          {/* <!-- Tab panes --> */}
          <div className="tab-content big-padding top-color-border tab-th modern-tabs-content">
            {displayTabContent(tabId)}
          </div>
          {/* <!-- eof .tab-content --> */}
        </div>
      );
    }
    return content;
  };

  const displayClubsNearBy = (type, clubs) => {
    let content = null;
    if (type === 'public' && clubs.length > 0) {
      content = (
        <div className="col-md-12">
          <div className="public-profile-divider">
            <Divider />
          </div>
          <h3>{t('profile_clubs_near')}</h3>
          <div className="choose-section club-public-profile-silder profile-club-near">
            <div className="overlay">
              <div className="choose-section-carousel clubs-carousel">
                <Carousel partialVisible responsive={responsive}>
                  {clubs.map((club) => {
                    return (
                      <div className="col">
                        <a href={`/${club.shortName}`}>
                          <div className="single-item overflow-hidden rounded-2xl">
                            <div className="icon-box">
                              {club.cover ? (
                                <img
                                  className="cover-image"
                                  src={`${imageBaseUrl}/${club.cover}`}
                                  alt="cover"
                                />
                              ) : (
                                <img
                                  className="cover-image"
                                  src={coverImage}
                                  alt="cover"
                                />
                              )}
                              {club.logo ? (
                                <img
                                  className="home-clubs-logo"
                                  src={`${imageBaseUrl}/${club.logo}`}
                                  alt="club-logo"
                                />
                              ) : (
                                <img className="home-clubs-logo" src={Icon} alt="club-logo" />
                              )}
                            </div>
                            <div className="text-box">
                              <h2 className="single-item-title">{club.name}</h2>
                              <p className="clubs-slider-desc single-item-description">
                                {displayDescription(club, selectedLanguage)}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return content;
  };

  const displayEvents = (type, items) => {
    if (type === 'public' && items.length > 0) {
      return (
        <div className="display-flex clubTabPanel">
          <div className="public-profile-divider">
            <Divider />
          </div>
          <h3>{t('profile_club_events')}</h3>
          <div className="choose-section club-public-profile-silder profile-club-near">
            <div className="overlay">
              <div className="choose-section-carousel event-carousel">
                <Carousel partialVisible responsive={responsive}>
                  {items.map((event) => {
                    return (
                      <div className="col">
                        <div className="single-item">
                          <div className="icon-box">
                            {event.image ? (
                              <img
                                className="home-clubs-logo"
                                src={`${imageBaseUrl}/${event.image}`}
                                alt="event-logo"
                              />
                            ) : (
                              <img className="home-clubs-logo" src={Icon} alt="event-logo" />
                            )}
                          </div>
                          <div className="text-box">
                            <h2 className="single-item-title">{event.title}</h2>
                            <h3 className="clubs-slider-desc single-item-description">
                              {displayDescription(event, selectedLanguage)}
                            </h3>
                          </div>
                          <div className="text-box">
                            <p>
                              {event.fromDate ? moment(event.fromDate).format('DD.MM.') : null}-{' '}
                              {event.toDate ? moment(event.toDate).format('DD.MM.YYYY') : null}
                            </p>
                            <p>
                              {event.location}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const displaySponsorSection = (type, items,subscriptions) => {
    let content = null;
    if (type === 'public') {
      content = (
        <div>
          <ul className="list-group">
            {items.map((product) => {
              let subscribed = subscriptions.some(sub => sub.product.id === product.id);
              return (
                <>
                  <li className="public-profile-sponsor-list justify-content-between">
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} className="product-image-profile">
                          <span>
                            {product.image ? (
                              <img
                                className={classes.productImage}
                                src={`${imageBaseUrl}/${product.image}`}
                                alt="product-img"
                              />
                            ) : (
                              <img
                                className={classes.productImage}
                                src={defaultImage}
                                alt="product-img"
                              />
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <span>
                            <h5 className="product-title sponsor-item-default-margin">
                              {product.title || ''}
                            </h5>
                            <p className="sponsor-item-default-margin product-description-profile">
                              {displayDescription(product, selectedLanguage)}
                            </p>
                            {!product.price || product.crowdFunding ? (
                              <p className={classes.emptyPrice+ " crowd-product"} />
                            ) : (
                              <p className="sponsor-item-default-margin price-profile">
                                CHF {product.price.toFixed(2)}   {product.recurringProduct? t(product.recurringPeriod) : ""}
                              </p>
                            )}
                            <PriceSlider selectedProduct={product} />
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <span>
                            <Button
                              className="product-button"
                              onClick={() => handleDonationClick(product)}
                              variant="outlined"
                              color={subscribed ? "inherit" : "primary"}
                              disabled={subscribed}
                            >
                              { !product.recurringProduct? t('profile_sponsor_button') : subscribed ?  t('subscribed') : t('subscribe') }
                            </Button>
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                    <div>{viewEmailVerificationConfirm()}</div>
                  </li>
                  <Divider />
                </>
              );
            })}
          </ul>
        </div>
      );
    }
    return content;
  };

  const displayMeetClubSection = (type, clubData) => {
    let content = null;
    if (type === 'public') {
      content = (
        <div>
          <h3>{t('profile_meet_the_club')}</h3>
          <p>{displayDescription(clubData, selectedLanguage)}</p>
        </div>
      );
    }
    return content;
  };

  const displayFavoriteButton = () => {
    if (isFavorited) {
      return (
        <p>
          <FavoriteOutlinedIcon className="favIcon" onClick={handleUnFavoriteClick} />{' '}
          {t('profile_remove_fav')}
        </p>
      );
    }
    return (
      <p>
        <FavoriteBorderOutlinedIcon className="favIcon" onClick={handleFavoriteClick} />{' '}
        {t('profile_add_fav')}
      </p>
    );
  };

  const displayEditButton = () => {
    if (profileType !== 'public') {
      if (isCompletedRegistration) {
        return (
          <Button className="btn-global" onClick={goToEditProfile} fullWidth variant="outlined">
            {t('profile_edit_profile')}
          </Button>
        );
      }
      return (
        <Button className="btn-global" onClick={goToRegistrationStep} fullWidth variant="outlined">
          {t('profile_complete_registration')}
        </Button>
      );
    }
    return null;
  };

  const displayGenerateQRCode = () => {
    if (profileType !== 'public') {
      return <QRCodePdf clubDetails={clubDetails} />;
    }
    return null;
  };

  return (
    <Fragment>
      <div>
        <Helmet>
          <title>{clubDetails.name || 'Club Name'} unterstützen auf MY SPONSOR</title>
          <meta name="description" content={"Unterstütze jetzt "+ clubDetails.name+ " auf MY SPONSOR mit einer Spende Deiner Wahl. Jeder Betrag fördert - es zählt nicht der Betrag, sondern der Wille."} />
          <meta property="og:title" content={clubDetails.name+ " unterstützen auf MY SPONSOR"}  />
          <meta property="og:description" content={"Unterstütze jetzt "+ clubDetails.name+ " auf MY SPONSOR mit einer Spende Deiner Wahl. Jeder Betrag fördert - es zählt nicht der Betrag, sondern der Wille."} />
          <meta property="og:image" content="" />
          <meta property="og:url" content={`https://www.mysponsor.ch/${clubDetails.shortName}`} />
          <meta name="twitter:title" content={clubDetails.name+ " unterstützen auf MY SPONSOR"} />
          <meta name="twitter:description" content={"Unterstütze jetzt "+ clubDetails.name+ " auf MY SPONSOR mit einer Spende Deiner Wahl. Jeder Betrag fördert - es zählt nicht der Betrag, sondern der Wille."} />
          <meta name="twitter:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <div className="cover-img">
        <CoverImageUpload clubDetails={clubDetails} type={profileType} />
        <div class="custom-shape-divider-bottom-1701331714 desktop">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill"></path>
          </svg>
        </div>
        <div class="custom-shape-divider-bottom-1701332664">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" class="shape-fill"></path>
          </svg>
        </div>
      </div>
      <section className="ls section-padding-top-150 section-padding-bottom-130 columns-padding-25 club-profile">
        <div className="container club-profile-container">
          <div className="row">
            <div className="col-md-4 club-infos">
              <div className="vertical-item content-padding bottom-color-border rounded text-center profile-box">
                <div className="item-media">
                  <ClubLogoUpload clubDetails={clubDetails} type={profileType} />
                </div>
                <div className="item-content title-mobile">
                  <h1 className="entry-title bottom-margin-0">{clubDetails.name || 'Club Name'}</h1>
                  {/* <p className="small-text highlight">Producer</p> */}
                </div>
              </div>
              <div className="profile-contact-div club">

                {(profileType !== 'public' || !clubDetails.hideAddress) && (
                    <div>
                      <strong>
                        <RoomIcon /> {t('address')}:
                      </strong>
                      <span className="profile-contact-details address">
                        {displayAddress(clubDetails)}
                      </span>
                    </div>
                )}

                <div>
                  <strong>
                    <EmailIcon /> {t('contact')}:
                  </strong>
                  <span className="profile-contact-details email">{clubDetails.email || ''}</span>
                </div>
                {profileType === 'public' && displayFavoriteButton()}
              </div>
              <div>{displayEditButton()}</div>
            </div>
            <div className="col-md-8 club-desc">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="entry-title bottom-margin-0 title-desktop">{clubDetails.name || 'Club Name'}</h1>
                  {!clubDetails.status ? (
                    <p className="club-status">{t('profile_not_verified')}</p>
                  ) : null}

                </div>
                <div className="col-md-8 qr-col">{displayGenerateQRCode()}</div>
              </div>
              <p>{products.length ? displaySponsorSection(profileType, products,subscriptions) : null}</p>
            </div>
          </div>
          <div className="club-description">{displayMeetClubSection(profileType, clubDetails)}</div>
          <div className="row">{displayTabs(profileType)}</div>
          <div className="row">{displayEvents(profileType, events)}</div>
          <div className="row">{displayClubsNearBy(profileType, clubList)}</div>
        </div>
      </section>
    </Fragment>
  );
};

BasicDetails.propTypes = {
  tabId: PropTypes.number,
  handleTabClick: PropTypes.func,
  handleCreateProductModal: PropTypes.func,
  handleFreeProductModal: PropTypes.func,
  handleCreateEventModal: PropTypes.func,
  handleFavoriteClick: PropTypes.func,
  handleUnFavoriteClick: PropTypes.func,
  handleDonationClick: PropTypes.func,
  goToEditProfile: PropTypes.func,
  profileType: PropTypes.string.isRequired,
  clubList: PropTypes.arrayOf(PropTypes.shape({})),
  isFreeProductCreated: PropTypes.bool.isRequired,
  handleDeactivateProduct: PropTypes.func,
  handleChangeTimeLine: PropTypes.func,
  periodFilter: PropTypes.string,
  clubDetails: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    status: PropTypes.bool,
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    hideAddress: PropTypes.bool,
  }).isRequired,
  isFavorited: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  topProducts: PropTypes.arrayOf(PropTypes.shape({})),
  topSponsers: PropTypes.arrayOf(PropTypes.shape({})),
  membershipProducts: PropTypes.arrayOf(PropTypes.shape({})),
  graphData: PropTypes.shape({}),
  isCompletedRegistration: PropTypes.bool,
  goToRegistrationStep: PropTypes.func,
};

BasicDetails.defaultProps = {
  tabId: null,
  handleTabClick: null,
  handleCreateProductModal: null,
  handleCreateEventModal: null,
  handleFavoriteClick: null,
  handleUnFavoriteClick: null,
  handleDonationClick: null,
  goToEditProfile: null,
  goToRegistrationStep: null,
  handleChangeTimeLine: null,
  handleDeactivateProduct: null,
  handleFreeProductModal: null,
  periodFilter: null,
  isFavorited: false,
  clubList: [],
  events: [],
  products: [],
  topProducts: [],
  topSponsers: [],
  membershipProducts: [],
  graphData: {},
  isCompletedRegistration: false,
};

export default BasicDetails;
