import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import DocUpload from '../Upload/DocumentUpload';
import CountrySelect from '../Select/CountrySelect';
import ConditionDE from '../../assets/docs/Konditionsbedingungen-DE.pdf';
import IBANInput from '../Pages/Club/IBANInput';

const useStyles = makeStyles(() => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));

// Destructuring props
const ThirdStep = ({
  handleBack,
  handleChange,
  handleNext,
  values: { bankCountry, accountName, currency, iban },
  errors,
  setDocuments,
  handleCountryChange,
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={12}>
          <Box style={{ margin: '30px 0 0' }}>
            <Typography variant="h6">{t('bank_details')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            name="bankCountry"
            label={t('country')}
            handleChange={(e, option) => handleCountryChange(option, 'bankCountry')}
            selectedCountry={bankCountry || ''}
            error={errors.bankCountry}
            helperText={errors.bankCountry}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('bank_account_owner')}
            name="accountName"
            placeholder={t('bank_account_owner')}
            margin="normal"
            value={accountName || ''}
            onChange={handleChange}
            required
            error={errors.accountName}
            helperText={errors.accountName}
            inputProps={{
              maxLength: 60,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <IBANInput
            value={iban || ''}
            onChange={handleChange}
            error={errors.iban}
            helperText={errors.iban}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('currency')}</InputLabel>
            <Select
              value={currency}
              onChange={handleChange}
              name="currency"
              error={errors.currency}
            >
              <MenuItem value="CHF">CHF</MenuItem>
              <MenuItem value="Euro">EUR</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors.currency}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box style={{ margin: '30px 0 0' }}>
            <Typography variant="h6">{t('documents')}</Typography>
          </Box>
        </Grid>
        <DocUpload uploadedDocuments={[]} uploadDocument={setDocuments} removeDocument={() => {}} />
      </Grid>
      <Grid item xs={12} style={{marginTop: 30, fontSize: '0.875rem' }}>
      <div>{t('condition_pre_cost')} {t('condition_pre')} <a href="/agb" target="_blank">{t('condition_agb')}</a>, <a href={ConditionDE} target="_blank" rel="noopener noreferrer">{t('condition_cond')}</a> {t('condition_btw')} <a href="/datenschutz" target="_blank">{t('condition_privacy')}</a> {t('condition_aft')}</div>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          className={classes.btnColor}
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          {t('back')}
        </Button>
        <Button variant="contained" className={classes.btnColor} onClick={handleNext}>
          {t('send')}
        </Button>
      </div>
    </Fragment>
  );
};

ThirdStep.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setDocuments: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    bankCountry: PropTypes.string,
    accountName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    bankCountry: PropTypes.string,
    accountName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.string,
  }),
  handleNext: PropTypes.func.isRequired,
};

ThirdStep.defaultProps = {
  errors: {},
};

export default ThirdStep;
