import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { baseUrl, authHeader, twintPaymentPageUrl } from '../../utils/helper';
import MasterCardLogo from '../../assets/img/master-3.png';
import TwintLogo from '../../assets/img/twint-logo-3.png';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 120,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancel: {
    margin: theme.spacing(0, 0, 2),
  },
  cardInput: {
    marginTop: 16,
    marginBottom: 16,
    borderBottom: '1px solid black',
    padding: '6px 0 7px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  masterCardLogo: {
    width: '40%',
    height: 'auto',
  },
  twintLogo: {
    width: '35%',
    height: 'auto',
  },
  paymentOptionLabel: {
    marginTop: 10,
    marginBottom: 0,
  },
}));

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  hidePostalCode: true,
};

const apiURL = `${baseUrl}/payments/pay`;

const product = {
  id: '',
  clubId: '',
  crowdFunding: true,
  price: '',
  title: '',
  totalFunded: 0,
  invoiceId: null,
};

const paymentMedthod = {
  twint: false,
  masterCard: false,
};

const CheckoutForm = (props) => {
  const { enteredAmount, setPriceError } = props;
  const { authUserData } = useSelector((state) => state.user);
  const { clubProduct } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(product);
  const [paymentMethodOption, setPaymentMethod] = useState(paymentMedthod);
  const [paymentError, setPaymentError] = useState({});
  const { t, i18n } = useTranslation();
  const [invoiceId, setInvoiceId] = useState(null)

  const selectedLanguage = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    if (location && location.state) {
      const { productId, invoiceId } = location.state;
      setInvoiceId(invoiceId)
      setSelectedProduct({
        ...product,
        id: productId,
      });
      // dispatch(getClubProductById({ id: productId }));
    } else {
      history.goBack();
    }
  }, [location, history, dispatch]);


  useEffect(() => {
    if (clubProduct && clubProduct.id) {
      setSelectedProduct({
        ...clubProduct,
        totalFunded: clubProduct.totalFunded ? clubProduct.totalFunded : 0,
      });
    }
  }, [clubProduct]);

  const hideSuccessAndGoBack = () => {
    setTimeout(() => {
      setSuccessMsg(false);
      const { from } = history.location.state || { from: { pathname: '/clubs' } };
      history.push('/payment/twint-success');
    }, 1500);
  };

  const displayError = (msg) => {
    setErrorMsg(msg);
    setLoading(false);
    setTimeout(() => {
      setErrorMsg('');
    }, 2000);
  };

  const paymentData = {
    amount:
      selectedProduct.price && !selectedProduct.crowdFunding
        ? selectedProduct.price
        : enteredAmount,
    quantity: 1,
    userId: authUserData.userId,
    clubId: selectedProduct.clubId,
    productId: selectedProduct.id,
    invoiceId: invoiceId
  };

  const handleStripeJsResult = async (result) => {
    try {
      if (result.error) {
        displayError(result.error.message);
      } else {
        paymentData.paymentIntentId = result.paymentIntent.id;
        const response = await axios.post(apiURL, paymentData, authHeader(authUserData.token));
        if (response && response.data) {
          setSuccessMsg(true);
          hideSuccessAndGoBack();
        }
      }
    } catch (error) {
      if (error && error.response) {
        displayError('Something went wrong');
      }
    }
  };

  const stripePaymentMethodHandler = async (result) => {
    try {
      paymentData.payment_method = result.id;
      const response = await axios.post(apiURL, paymentData, authHeader(authUserData.token));
      if (response && response.data) {
        setLoading(false);
        if (response.data.requires_action) {
          stripe
            .confirmCardPayment(response.data.payment_intent_client_secret)
            .then(handleStripeJsResult);
        } else {
          setSuccessMsg(true);
          hideSuccessAndGoBack();
        }
      }
    } catch (error) {
      if (error && error.response) {
        displayError('Something went wrong');
      }
    }
  };

  const twintPaymentMethodHandler = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/payments/initialize-twint`,
        paymentData,
        authHeader(authUserData.token)
      );
      if (response && response.data) {
        if (response.data.transactionDetails) {
          window.location.replace(
            `${twintPaymentPageUrl}/${response.data.transactionDetails.transactionId}`
          );
        } else {
          displayError('Something went wrong');
        }
      }
    } catch (error) {
      displayError('Something went wrong');
    }
  };

  const validatePaymentForm = () => {
    let isValid = true;
    if (!enteredAmount && (selectedProduct.crowdFunding || !selectedProduct.price)) {
      isValid = false;
      setPriceError('Please enter donation amount');
    }
    if (selectedProduct.crowdFunding && selectedProduct.price === selectedProduct.totalFunded) {
      isValid = false;
      setPaymentError({
        ...paymentError,
        donation: `You can't donate at this stage`,
      });
    }
    if (
      selectedProduct.crowdFunding &&
      enteredAmount > selectedProduct.price - selectedProduct.totalFunded
    ) {
      isValid = false;
      setPriceError(
        `Maximum amount you can donate for this product $${selectedProduct.price - selectedProduct.totalFunded
        }`
      );
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePaymentForm()) {
      if (paymentMethodOption.masterCard) {
        if (!stripe || !elements) {
          return;
        }
        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        if (error) {
          setLoading(false);
        } else {
          stripePaymentMethodHandler(paymentMethod);
        }
      } else if (paymentMethodOption.twint) {
        twintPaymentMethodHandler();
      }
    }
  };

  const handlePaymentOptionChange = (e) => {
    const selectedOption = e.target.name;
    if (selectedOption === 'masterCard') {
      setPaymentMethod({
        masterCard: true,
        twint: false,
      });
    } else if (selectedOption === 'twint') {
      setPaymentMethod({
        masterCard: false,
        twint: true,
      });
    }
  };

  const isPaymentEnabled = paymentMethodOption.masterCard || paymentMethodOption.twint;
  return (
    <Container component="main" maxWidth="xs" className="paymentform">
      <form className={classes.form} onSubmit={handleSubmit}>
        {successMsg && <Alert severity="success">Payment done SuccessFully!</Alert>}
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        {paymentError && paymentError.donation ? (
          <Alert severity="error">{paymentError.donation}</Alert>
        ) : null}
        <p className="payment-sub-heading">
          {t('payment_your_payment')} <span>{t('payment_your_payment_span')}</span>
        </p>
        <Grid container spacing={3} className="payment-method-box">
          <Grid style={{ paddingTop: 0, marginTop: 25, marginBottom: 35 }} item xs={12} sm={6} className="payment-method">
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentMethodOption.masterCard}
                  onChange={handlePaymentOptionChange}
                  name="masterCard"
                  color="primary"
                  id="card"
                />
              }
            />
            <label for="card"><img className={classes.masterCardLogo} src={MasterCardLogo} alt="master-card" /></label>
          </Grid>
          {(!clubProduct.recurringProduct || ( clubProduct.productType != null && clubProduct.productType == 'membership')) &&
            <Grid style={{ paddingTop: 0, marginTop: 25, marginBottom: 35 }} item xs={12} sm={6} className="payment-method">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={paymentMethodOption.twint}
                    onChange={handlePaymentOptionChange}
                    name="twint"
                    color="primary"
                    id="twint"
                  />
                }
              />
              <label for="twint"><img className={classes.twintLogo} src={TwintLogo} alt="twint" /></label>
            </Grid>}
        </Grid>
        {paymentMethodOption.masterCard ? (
          <TextField
            id="standard-name-input"
            label={t('nameoncard')}
            required
            fullWidth
          />
        ) : null}
        {paymentMethodOption.masterCard ? (
          <div className={classes.cardInput}>
            <CardElement options={CARD_OPTIONS} />
          </div>
        ) : null}
        <Grid item xs={12} sm={12} style={{ fontSize: '0.675rem' }}>
          <div>{t('pre_buy')} <a href="/agb" target="_blank">{t('condition_agb')}</a> und <a href="/datenschutz" target="_blank">{t('condition_privacy')}</a> {t('condition_aft')}</div>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="pay-btn"
          disabled={!stripe || loading || !isPaymentEnabled}
        >
          {t('payment_button')}
          {loading && <CircularProgress size={24} color="primary" />}
        </Button>
      </form>
    </Container>
  );
};

CheckoutForm.propTypes = {
  enteredAmount: PropTypes.string,
  setPriceError: PropTypes.func.isRequired,
};

CheckoutForm.defaultProps = {
  enteredAmount: '',
};

export default CheckoutForm;