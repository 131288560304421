import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isDecimalNumber } from '../../utils/helper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, CircularProgress, Typography } from '@material-ui/core';

export default function FormPropsTextFields(props) {
  const { t, i18n } = useTranslation();
  const {
    product,
    errors,
    handleChange,
    isFreeSponsorModalOpen,
    handleProductDescChange,
    handleTranslateOptions,
    handleProductType,
    setIsTranslating
  } = props;

  const [type, setType] = useState(product.productType || (product.recurringProduct === "true" ? "subscription" : product.crowdFunding ? "crowd" : "fixed"));
  const [description, setDescription] = useState(product.description || '');
  const [isTranslating, setIsLocalTranslating] = useState(false);

  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  // Set default recurring period
  useEffect(() => {
    if (type === 'subscription' && !product.recurringPeriod) {
      handleChange({
        target: {
          name: 'recurringPeriod',
          value: 'week'
        }
      });
    }
  }, [type]);

  // Handle description changes and translation
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value.slice(0, 240);
    setDescription(newDescription);
    handleChange({ target: { name: 'description', value: newDescription } });
  };

  if (isFreeSponsorModalOpen) product.productType = 'open';

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={3} xs={12} sm={7}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="standard-name-input"
            name="title"
            label={t('product_title')}
            required
            fullWidth
            value={product.title}
            error={errors.title}
            helperText={errors.title}
            onChange={handleChange}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        {!isFreeSponsorModalOpen && (
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-price-input"
              name="price"
              label={t('product_price')}
              required
              fullWidth
              value={product.price}
              error={errors.price}
              helperText={errors.price}
              onKeyPress={isDecimalNumber}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            id="standard-date-input"
            name="expiryDate"
            type="date"
            label={t('product_expiry_date')}
            defaultValue="2021-12-31"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={product.expiryDate}
            error={errors.expiryDate}
            helperText={errors.expiryDate}
            onChange={handleChange}
          />
        </Grid>
        {!isFreeSponsorModalOpen && (
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>{t('product_type')}</InputLabel>
              <Select
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  handleProductType(e);
                }}
                name="productType"
              >
                <MenuItem value="fixed">{t('fixed')}</MenuItem>
                <MenuItem value="subscription">{t('sub')}</MenuItem>
                <MenuItem value="crowd">{t('crowd')}</MenuItem>
                <MenuItem value="membership">{t('membership')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {type === 'subscription' && (
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel>{t('recurring_period')}</InputLabel>
              <Select
                value={product.recurringPeriod || "week"}
                onChange={handleChange}
                name="recurringPeriod"
              >
                <MenuItem value="week">{t('week')}</MenuItem>
                <MenuItem value="month">{t('month')}</MenuItem>
                <MenuItem value="year">{t('year')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {type === 'membership' && (
          <>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t('recurring_period')}</InputLabel>
                <Select
                  value={product.recurringPeriod || "month"}
                  onChange={handleChange}
                  name="recurringPeriod"
                >
                  <MenuItem value="week">{t('week')}</MenuItem>
                  <MenuItem value="month">{t('month')}</MenuItem>
                  <MenuItem value="threeMonths">{t('threeMonths')}</MenuItem>
                  <MenuItem value="fourMonths">{t('fourMonths')}</MenuItem>
                  <MenuItem value="sixMonths">{t('sixMonths')}</MenuItem>
                  <MenuItem value="year">{t('year')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-date-input"
                name="startingDate"
                type="date"
                label={t('membership_product_date')}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={product.startingDate}
                error={errors.startingDate}
                helperText={errors.startingDate}
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={3} sm={12} className="description-product-modal">
        <Grid item xs={12} sm={12}>
          <TextField
            id="standard-description-input"
            name="description"
            label={t('product_description')}
            required
            fullWidth
            multiline
            rows={4}
            inputProps={{
              maxLength: 240,
            }}
            value={description}
            error={errors.description}
            helperText={errors.description}
            onChange={handleDescriptionChange}
          />
        </Grid>
      </Grid>
    </form>
  );
}

FormPropsTextFields.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    expiryDate: PropTypes.string,
    isTranlationCompleted: PropTypes.bool,
    recurringPeriod: PropTypes.string,
    startingDate: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    expiryDate: PropTypes.string,
    productImage: PropTypes.string,
    startingDate: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  handleProductType: PropTypes.func.isRequired,
  handleProductDescChange: PropTypes.func.isRequired,
  handleTranslateOptions: PropTypes.func.isRequired,
  isFreeSponsorModalOpen: PropTypes.bool,
  setIsTranslating: PropTypes.func.isRequired,
};

FormPropsTextFields.defaultProps = {
  errors: {},
  isFreeSponsorModalOpen: false,
};