import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { addMember, getMembers } from '../../actions/membershipAction';
import { validateEmail } from '../../utils/helper';

const GridTextField = ({ label, value, error, onChange, type = 'text', required = false }) => (
  <Grid item xs={12} sm={2}>
    <TextField
      label={label}
      value={value}
      error={!!error}
      helperText={error}
      onChange={(e) => onChange(e.target.value)}
      type={type}
      required={required}
      fullWidth
    />
  </Grid>
);

GridTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
};

const ClubAddMemberModal = ({
  open,
  toggleAddMemberModal,
  handleSelectionChange,
  data,
  product,
  setProduct,
  pageDetails,
  initialAmount,
  setInitialAmount,
  startingDate,
  setStartingDate,
  authUserData,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language') || 'du';
    i18n.changeLanguage(selectedLanguage);
  }, [i18n]);
  const fetchMembers = () => {
    dispatch(
      getMembers({
        clubId: authUserData.clubId,
        productId: product ? product.id : null,
        ...pageDetails,
      })
    );
    if (product) setProduct(product);
  };

  useEffect(() => {
    fetchMembers();
  }, [pageDetails]);


  const validateValues = () => {
    const newErrors = {};
    if (!validateEmail(email)) newErrors.email = t('invalid_email');
    if (!firstName.trim()) newErrors.firstName = t('invalid_first_name');
    if (!lastName.trim()) newErrors.lastName = t('invalid_last_name');
    if (initialAmount == null || initialAmount < 5)
      newErrors.initialAmount = t('invalid_initial_amount');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddMember = () => {
    if (validateValues()) {
      const memberData = {
        firstName,
        lastName,
        userEmail: email,
        clubId: authUserData.clubId,
        productId: product.id,
        initialAmount,
        startingDate,
      };
      dispatch(addMember(memberData, t('add_member_success'), t('add_member_error')));
      setFirstName('');
      setLastName('');
      setEmail('');
      fetchMembers();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      className="width-xl-60-modal create-product-modal"
      id="membershipAddMember"
    >
      <DialogTitle className="modal-title-center">{t('add_member')}</DialogTitle>

      <DialogContent>
        <div className="product-create-btn">
          <h4>{t('mebership_type')}</h4>
          <FormControl id="memberShipTypeDropdown" className="w-25">
            <Select value={product} onChange={handleSelectionChange}>
              {data.map((productItem) => (
                <MenuItem key={productItem.id} value={productItem}>
                  {productItem.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container spacing={3} alignItems="center" id="addMemberForm">
            <GridTextField
              label={t('first_name')}
              value={firstName}
              error={errors.firstName}
              onChange={setFirstName}
              required
            />
            <GridTextField
              label={t('last_name')}
              value={lastName}
              error={errors.lastName}
              onChange={setLastName}
              required
            />
            <GridTextField
              label={t('Email')}
              value={email}
              error={errors.email}
              onChange={setEmail}
            />
            <GridTextField
              label={t('initial_amount')}
              value={initialAmount}
              error={errors.initialAmount}
              onChange={setInitialAmount}
              type="number"
              required
            />
            <GridTextField
              label={t('starting_date')}
              value={startingDate}
              error={errors.startingDate}
              onChange={setStartingDate}
              type="date"
              required
            />
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className="action-buttons-modal">
        <Button className="cancel-button" onClick={toggleAddMemberModal} variant="contained">
          {t('close')}
        </Button>
        <Button
          variant="contained"
          className="btn-global-filled"
          onClick={handleAddMember}
          disabled={!product}
        >
          {t('add_member')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClubAddMemberModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleAddMemberModal: PropTypes.func.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  setProduct: PropTypes.func.isRequired,
  pageDetails: PropTypes.object.isRequired,
  initialAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInitialAmount: PropTypes.func.isRequired,
  startingDate: PropTypes.string,
  setStartingDate: PropTypes.func.isRequired,
  authUserData: PropTypes.shape({
    clubId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export default ClubAddMemberModal;
