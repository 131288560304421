import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import DocUpload from '../Upload/DocumentUpload';
import { getClubRole } from '../../utils/helper';
import IBANInput from '../Pages/Club/IBANInput';

const useStyles = makeStyles(() => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));

// Destructuring props
const ThirdStep = ({
  handleChange,
  handleSave,
  values: { accountName, iban, currency, country },
  errors,
  documents,
  saveDocuments,
  deleteDocuments,
  authUserData,
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  const clubRole = getClubRole(authUserData)

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={12}>
          <Box style={{ margin: '30px 0 0' }}>
            <Typography variant="h6">{t('bank_details')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('country')}
            name="country"
            placeholder={t('country')}
            margin="normal"
            value={country || ''}
            onChange={handleChange}
            required
            error={errors.country}
            helperText={errors.country}
            inputProps={{
              maxLength: 10,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('bank_account_owner')}
            name="accountName"
            placeholder={t('bank_account_owner')}
            margin="normal"
            value={accountName || ''}
            onChange={handleChange}
            required
            error={errors.accountName}
            helperText={errors.accountName}
            inputProps={{
              maxLength: 50,
            }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <IBANInput
            value={iban || ''}
            onChange={handleChange}
            error={errors.iban}
            helperText={errors.iban}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('currency')}</InputLabel>
            <Select
              value={currency}
              onChange={handleChange}
              name="currency"
              error={errors.currency}
              disabled
            >
              <MenuItem value="CHF">CHF</MenuItem>
              <MenuItem value="Euro">Euro</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors.currency}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box style={{ margin: '30px 0 0' }}>
            <Typography variant="h6">{t('documents')}</Typography>
          </Box>
        </Grid>
        <DocUpload
          uploadedDocuments={documents}
          uploadDocument={saveDocuments}
          removeDocument={deleteDocuments}
        />
      </Grid>
      {clubRole !== 'ROLE_CLUB_FINANCE' && (
        <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
          <Button variant="contained" className={classes.btnColor} onClick={handleSave}>
            {t('save')}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

ThirdStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    country: PropTypes.string,
    accountName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    country: PropTypes.string,
    accountName: PropTypes.string,
    currency: PropTypes.string,
    iban: PropTypes.number,
  }),
  handleSave: PropTypes.func.isRequired,
  saveDocuments: PropTypes.func.isRequired,
  deleteDocuments: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
};

ThirdStep.defaultProps = {
  errors: {},
  documents: [],
};

export default ThirdStep;
