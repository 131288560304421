import React, { useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const IBANInput = ({ value, onChange, error, helperText, disabled, required }) => {
  const formatIBAN = (iban) => {
    return iban.replace(/(.{4})/g, '$1 ').trim();
  };

  const [formattedValue, setFormattedValue] = useState(formatIBAN(value));

  const handleChange = (event) => {
    const input = event.target.value.replace(/\s/g, '').toUpperCase();
    const formatted = formatIBAN(input);
    setFormattedValue(formatted);
    onChange({ target: { name: 'iban', value: input } });
  };

  const validateIBAN = (iban) => {
    const ibanStripped = iban.replace(/\s/g, '');

    // Basic format check
    if (!/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/.test(ibanStripped)) {
      return false;
    }

    // Rearrange and convert to integer
    const rearranged = ibanStripped.slice(4) + ibanStripped.slice(0, 4);
    let total = 0;
    for (let i = 0; i < rearranged.length; i++) {
      const char = rearranged.charAt(i);
      const value = parseInt(char, 36);
      if (value > 9) {
        total = (total * 100 + value) % 97;
      } else {
        total = (total * 10 + value) % 97;
      }
    }

    return total === 1;
  };

  const maxLength = useMemo(() => {
    const strippedValue = value.replace(/\s/g, '');
    if (strippedValue.startsWith('CH')) {
      return 21 + 5; // 21 characters + 5 spaces
    }
    return 34 + 8; // 34 characters + 8 spaces
  }, [value]);

  return (
    <TextField
      fullWidth
      label="IBAN"
      name="iban"
      placeholder="IBAN"
      value={formattedValue}
      onChange={handleChange}
      margin="normal"
      required
      error={error || (value && !validateIBAN(value))}
      helperText={error || (value && !validateIBAN(value) ? 'Invalid IBAN format' : helperText)}
      disabled={disabled}
      inputProps={{
        maxLength: maxLength,
      }}
    />
  );
};

IBANInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default IBANInput;
